
.file-drop-area {
    position: relative;
    align-items: center;
    max-width: 100%;
    padding: 25px;
    border: 2px dotted #ddd;
    border-radius: 4px;
    margin: 1.5em 0;
    height: 200px;
    width: 100%;
    transition: 0.2s

}

.choose-file-button {
    flex-shrink: 0;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    padding: 8px 15px;
    margin: auto;
    font-size: 12px;
    text-transform: uppercase;
}

.file-message {
    text-align: center;
    font-size: small;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.file-input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0
}
