// Fonts
@import 'variables';

@import 'dragAndDropFileUpßload';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

// Variables
@import 'eos-font';
@import 'eos-icons';


#app{
    background-color: $light-green-20;
}

h1,h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5{
    font-weight: 800;
    strong{
        color:$primary;
    }
}

.navbar-brand {
    h1 {
        font-size: 1em;
        margin-bottom: 0px;
    }
    .tagline{
        font-size: .75em;
    }
}


//Tabs anpassungen
.nav-tabs .nav-link{
        color: $dark;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: transparent;
    border-top-color: transparent;
    border-right: none;
    border-left: none;
    font-weight: bold;
    color: $dark;
}



/**
    Remove Arrows from Number inputs
 */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.form-control:focus {
    box-shadow: none;
}
