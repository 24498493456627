@function tint-color($color, $weight) {
    @return mix(white, $color, $weight);
}

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
    @return mix(black, $color, $weight);
}


$primary:       #EA0029;
$secondary:     #31171F;
$success:       #00BF8D;
$info:          #4364F7;
$warning:       #FFC346;
$danger:        #E14252;
$light:         #E0EEF0;
$dark:          #31171F;
$black:          #31171F;


$light-green-20: #E0EEF0;
$light-green-15: #E8F2F3;
$light-black-15: #E0DCDD;
$light-black-10: #EAE7E8;

//Text Font-Family
$font-family-base: "EOS Regular";

//Text size
$font-size-root: 14px;

//Text color
$body-color: $secondary;

//Text Headings
$h1-font-size: 48px;
$h2-font-size: 30px;
$h3-font-size: 24px;
$h4-font-size: 20px;
$h5-font-size: 18px;
$h6-font-size: 16px;
$h7-font-size: 14px;


//Btn Border Radius
$btn-border-radius-sm: 16px;
$btn-padding-y-sm: 14px;
$btn-padding-y-sm:3px;

$btn-border-radius: 16px;
$btn-font-size: 14px;
$btn-padding-y:3px;

$btn-border-radius-lg: 24px;
$btn-font-size-lg: 18px;
$btn-padding-y-lg:3px;

//Modal Stuff
$modal-content-border-radius: 0px;
$modal-header-border-color: $dark;


.modal-body{
    background-color: tint-color($dark, 90%);
}
.modal-header, .modal-footer{
    background-color: tint-color($dark, 80%);
}


//Input Stuff
$input-border-radius: 0px;


// Tab

$nav-tabs-border-color: tint-color($dark, 80%);
$nav-tabs-link-active-border-color: $dark;
$nav-tabs-border-width: 2px;
$nav-link-color: $dark;
