/* EOS Regular */
@font-face {
    font-family:"EOS Regular";
    src:url("/font/EOS-Regular.woff2") format("woff2"),
    url("/font/EOS-Regular.woff") format("woff"),
    url("/font/EOS-Regular.ttf") format("truetype");
    font-style:normal;font-weight:400;
}
@font-face {
    font-family:"webfont";
    src:url("/font/EOS-Regular.woff2") format("woff2"),
    url("/font/EOS-Regular.woff") format("woff"),
    url("/font/EOS-Regular.ttf") format("truetype");
    font-style:normal;font-weight:400;
}
@font-face {
    font-family:"EOS RegularItalic";
    src:url("/font/EOS-RegularItalic.woff2") format("woff2"),
    url("/font/EOS-RegularItalic.woff") format("woff"),
    url("/font/EOS-RegularItalic.ttf") format("truetype");
    font-style:italic;font-weight:400;
}
@font-face {
    font-family:"webfont";
    src:url("/font/EOS-RegularItalic.woff2") format("woff2"),
    url("/font/EOS-RegularItalic.woff") format("woff"),
    url("/font/EOS-RegularItalic.ttf") format("truetype");
    font-style:italic;font-weight:400;
}
/* EOS Bold */
@font-face {
    font-family:"EOS Regular";
    src:url("/font/EOS-Bold.woff2") format("woff2"),
    url("/font/EOS-Bold.woff") format("woff"),
    url("/font/EOS-Bold.ttf") format("truetype");
    font-style:normal;font-weight:500;
}
@font-face {
    font-family:"webfont";
    src:url("/font/EOS-Bold.woff2") format("woff2"),
    url("/font/EOS-Bold.woff") format("woff"),
    url("/font/EOS-Bold.ttf") format("truetype");
    font-style:normal;font-weight:500;
}
@font-face {
    font-family:"EOS Regular";
    src:url("/font/EOS-BoldItalic.woff2") format("woff2"),
    url("/font/EOS-BoldItalic.woff") format("woff"),
    url("/font/EOS-BoldItalic.ttf") format("truetype");
    font-style:italic;font-weight:500;
}
@font-face {
    font-family:"webfont";
    src:url("/font/EOS-BoldItalic.woff2") format("woff2"),
    url("/font/EOS-BoldItalic.woff") format("woff"),
    url("/font/EOS-BoldItalic.ttf") format("truetype");
    font-style:italic;font-weight:500;
}
/* EOS Light */
@font-face {
    font-family:"EOS Regular";
    src:url("/font/EOS-Light.woff2") format("woff2"),
    url("/font/EOS-Light.woff") format("woff"),
    url("/font/EOS-Light.ttf") format("truetype");
    font-style:normal;font-weight:300;
}
@font-face {
    font-family:"webfont";
    src:url("/font/EOS-Light.woff2") format("woff2"),
    url("/font/EOS-Light.woff") format("woff"),
    url("/font/EOS-Light.ttf") format("truetype");
    font-style:normal;font-weight:300;
}
@font-face {
    font-family:"EOS Regular";
    src:url("/font/EOS-LightItalic.woff2") format("woff2"),
    url("/font/EOS-LightItalic.woff") format("woff"),
    url("/font/EOS-LightItalic.ttf") format("truetype");
    font-style:italic;font-weight:300;
}
@font-face {
    font-family:"webfont";
    src:url("/font/EOS-LightItalic.woff2") format("woff2"),
    url("/font/EOS-LightItalic.woff") format("woff"),
    url("/font/EOS-LightItalic.ttf") format("truetype");
    font-style:italic;font-weight:300;
}
/* EOS Ultrabold */
@font-face {
    font-family:"EOS Regular";
    src:url("/font/EOS-Ultrabold.woff2") format("woff2"),
    url("/font/EOS-Ultrabold.woff") format("woff"),
    url("/font/EOS-Ultrabold.ttf") format("truetype");
    font-style:normal;font-weight:800;
}
@font-face {
    font-family:"webfont";
    src:url("/font/EOS-Ultrabold.woff2") format("woff2"),
    url("/font/EOS-Ultrabold.woff") format("woff"),
    url("/font/EOS-Ultrabold.ttf") format("truetype");
    font-style:normal;font-weight:800;
}
@font-face {
    font-family:"EOS Regular";
    src:url("/font/EOS-UltraboldItalic.woff2") format("woff2"),
    url("/font/EOS-UltraboldItalic.woff") format("woff"),
    url("/font/EOS-UltraboldItalic.ttf") format("truetype");
    font-style:italic;font-weight:800;
}
@font-face {
    font-family:"webfont";
    src:url("/font/EOS-UltraboldItalic.woff2") format("woff2"),
    url("/font/EOS-UltraboldItalic.woff") format("woff"),
    url("/font/EOS-UltraboldItalic.ttf") format("truetype");
    font-style:italic;font-weight:800;
}
