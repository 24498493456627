.bi-eos:before{
    position: relative;
    top: 1px;
    width: 1.2em;
    filter: invert(6%) sepia(23%) saturate(4431%) hue-rotate(311deg) brightness(87%) contrast(88%);
}

.bi-eos-primary.bi-eos:before{
    filter: invert(16%) sepia(80%) saturate(6310%) hue-rotate(343deg) brightness(87%) contrast(112%);
}

.bi-eos-white.bi-eos:before{
    filter: invert(99%) sepia(6%) saturate(15%) hue-rotate(248deg) brightness(107%) contrast(100%);
}

.btn .bi-eos:before{
    left:-8px;
    top: 2px;
}

.bi-eos-huge:before{
    width: 150px;
}

.bi-eos-sustainability_electric_car:before{content: url('/icons/EOS_Sustainability_Electric_car_sRGB.svg');}
.bi-eos-sustainability_climate_neutral_letter:before{content: url('/icons/EOS_Sustainability_Climate_neutral_letter_sRGB.svg');}
.bi-eos-working-abroad:before{content: url('/icons/EOS_Working abroad_sRGB.svg');}
.bi-eos-germany:before{content: url('/icons/EOS_germany_sRGB.svg');}
.bi-eos-project-week:before{content: url('/icons/EOS_project week_sRGB.svg');}
.bi-eos-learn-fast:before{content: url('/icons/EOS_learn fast_sRGB.svg');}
.bi-eos-work-life-balance:before{content: url('/icons/EOS_work-life-balance_sRGB.svg');}
.bi-eos-educational-journey:before{content: url('/icons/EOS_educational journey_sRGB.svg');}
.bi-eos-further-development:before{content: url('/icons/EOS_further development_sRGB.svg');}
.bi-eos-further-education:before{content: url('/icons/EOS_further education_sRGB.svg');}
.bi-eos-operating-system-choice:before{content: url('/icons/EOS_operating system choice_sRGB.svg');}
.bi-eos-questions-v2:before{content: url('/icons/EOS_questions V2_sRGB.svg');}
.bi-eos-resource-gain:before{content: url('/icons/EOS_resource gain_sRGB.svg');}
.bi-eos-press-videos:before{content: url('/icons/EOS_press videos_sRGB.svg');}
.bi-eos-positive-rating:before{content: url('/icons/EOS_positive rating_sRGB.svg');}
.bi-eos-negative-rating:before{content: url('/icons/EOS_negative rating_sRGB.svg');}
.bi-eos-media-publishing-houses:before{content: url('/icons/EOS_media-publishing houses_sRGB.svg');}
.bi-eos-insurance:before{content: url('/icons/EOS_insurance_sRGB.svg');}
.bi-eos-infographics:before{content: url('/icons/EOS_infographics_sRGB.svg');}
.bi-eos-good-reputation:before{content: url('/icons/EOS_good reputation_sRGB.svg');}
.bi-eos-e-commerce:before{content: url('/icons/EOS_e-commerce_sRGB.svg');}
.bi-eos-calculation:before{content: url('/icons/EOS_calculation_sRGB.svg');}
.bi-eos-unisex:before{content: url('/icons/EOS_unisex_sRGB.svg');}
.bi-eos-pound-v2:before{content: url('/icons/EOS_pound V2_sRGB.svg');}
.bi-eos-fintech-pound:before{content: url('/icons/EOS_fintech pound_sRGB.svg');}
.bi-eos-fintech-euro:before{content: url('/icons/EOS_fintech euro_sRGB.svg');}
.bi-eos-fintech-dollar:before{content: url('/icons/EOS_fintech dollar_sRGB.svg');}
.bi-eos-euro-v2:before{content: url('/icons/EOS_euro V2_sRGB.svg');}
.bi-eos-dollar-v2:before{content: url('/icons/EOS_dollar V2_sRGB.svg');}
.bi-eos-digital-payment-flow-pound:before{content: url('/icons/EOS_digital payment flow pound_sRGB.svg');}
.bi-eos-digital-payment-flow-euro:before{content: url('/icons/EOS_digital payment flow euro_sRGB.svg');}
.bi-eos-digital-payment-flow-dollar:before{content: url('/icons/EOS_digital payment flow dollar_sRGB.svg');}
.bi-eos-debt-collection-pound:before{content: url('/icons/EOS_debt collection pound_sRGB.svg');}
.bi-eos-debt-collection-euro:before{content: url('/icons/EOS_debt collection euro_sRGB.svg');}
.bi-eos-debt-collection-dollar:before{content: url('/icons/EOS_debt collection dollar_sRGB.svg');}
.bi-eos-claim-management-pound:before{content: url('/icons/EOS_claim management pound_sRGB.svg');}
.bi-eos-claim-management-euro:before{content: url('/icons/EOS_claim management euro_sRGB.svg');}
.bi-eos-claim-management-dollar:before{content: url('/icons/EOS_claim management dollar_sRGB.svg');}
.bi-eos-amount-pound:before{content: url('/icons/EOS_amount pound_sRGB.svg');}
.bi-eos-amount-euro:before{content: url('/icons/EOS_amount euro_sRGB.svg');}
.bi-eos-amount-dollar:before{content: url('/icons/EOS_amount dollar_sRGB.svg');}
.bi-eos-documents:before{content: url('/icons/EOS_documents_sRGB.svg');}
.bi-eos-location:before{content: url('/icons/EOS_location_sRGB.svg');}
.bi-eos-knowledge:before{content: url('/icons/EOS_knowledge_sRGB.svg');}
.bi-eos-employee-events-v2:before{content: url('/icons/EOS_employee events V2_sRGB.svg');}
.bi-eos-woman:before{content: url('/icons/EOS_woman_sRGB.svg');}
.bi-eos-wifi-v1:before{content: url('/icons/EOS_wifi V1_sRGB.svg');}
.bi-eos-upload:before{content: url('/icons/EOS_upload_sRGB.svg');}
.bi-eos-unsecured-debts:before{content: url('/icons/EOS_unsecured debts_sRGB.svg');}
.bi-eos-travel:before{content: url('/icons/EOS_travel_sRGB.svg');}
.bi-eos-technologies:before{content: url('/icons/EOS_technologies_sRGB.svg');}
.bi-eos-team:before{content: url('/icons/EOS_team_sRGB.svg');}
.bi-eos-tasks:before{content: url('/icons/EOS_tasks_sRGB.svg');}
.bi-eos-strategy-v3:before{content: url('/icons/EOS_strategy V3_sRGB.svg');}
.bi-eos-strategy-v2:before{content: url('/icons/EOS_strategy V2_sRGB.svg');}
.bi-eos-strategy-v1:before{content: url('/icons/EOS_strategy V1_sRGB.svg');}
.bi-eos-smiley-positive:before{content: url('/icons/EOS_smiley positive_sRGB.svg');}
.bi-eos-smiley-neutral:before{content: url('/icons/EOS_smiley neutral_sRGB.svg');}
.bi-eos-smiley-negative:before{content: url('/icons/EOS_smiley negative_sRGB.svg');}
.bi-eos-smartphone:before{content: url('/icons/EOS_smartphone_sRGB.svg');}
.bi-eos-server-v2:before{content: url('/icons/EOS_server V2_sRGB.svg');}
.bi-eos-server-v1:before{content: url('/icons/EOS_server V1_sRGB.svg');}
.bi-eos-seminar-programs:before{content: url('/icons/EOS_seminar programs_sRGB.svg');}
.bi-eos-search:before{content: url('/icons/EOS_search_sRGB.svg');}
.bi-eos-safety:before{content: url('/icons/EOS_safety_sRGB.svg');}
.bi-eos-safety-digital:before{content: url('/icons/EOS_safety digital_sRGB.svg');}
.bi-eos-questions-v3:before{content: url('/icons/EOS_questions V3_sRGB.svg');}
.bi-eos-project-goal:before{content: url('/icons/EOS_project goal_sRGB.svg');}
.bi-eos-print:before{content: url('/icons/EOS_print_sRGB.svg');}
.bi-eos-positive-development v1:before{content: url('/icons/EOS_positive development V1_sRGB.svg');}
.bi-eos-percentages:before{content: url('/icons/EOS_percentages_sRGB.svg');}
.bi-eos-pension-plan:before{content: url('/icons/EOS_pension plan_sRGB.svg');}
.bi-eos-parking area:before{content: url('/icons/EOS_parking area_sRGB.svg');}
.bi-eos-outsourcing-v1:before{content: url('/icons/EOS_outsourcing V1_sRGB.svg');}
.bi-eos-organization:before{content: url('/icons/EOS_organization_sRGB.svg');}
.bi-eos-organic-fruits:before{content: url('/icons/EOS_organic fruits_sRGB.svg');}
.bi-eos-office-climate:before{content: url('/icons/EOS_office climate_sRGB.svg');}
.bi-eos-networking:before{content: url('/icons/EOS_networking_sRGB.svg');}
.bi-eos-money:before{content: url('/icons/EOS_money_sRGB.svg');}
.bi-eos-mobile-working-v1:before{content: url('/icons/EOS_mobile working V1_sRGB.svg');}
.bi-eos-menu:before{content: url('/icons/EOS_menu_sRGB.svg');}
.bi-eos-man:before{content: url('/icons/EOS_man_sRGB.svg');}
.bi-eos-lunch-v2:before{content: url('/icons/EOS_lunch V2_sRGB.svg');}
.bi-eos-lunch-v1:before{content: url('/icons/EOS_lunch V1_sRGB.svg');}
.bi-eos-local-public-transport:before{content: url('/icons/EOS_local public transport_sRGB.svg');}
.bi-eos-law:before{content: url('/icons/EOS_law_sRGB.svg');}
.bi-eos-it-consumer-basket:before{content: url('/icons/EOS_IT consumer basket_sRGB.svg');}
.bi-eos-info:before{content: url('/icons/EOS_info_sRGB.svg');}
.bi-eos-household:before{content: url('/icons/EOS_household_sRGB.svg');}
.bi-eos-holiday-v2:before{content: url('/icons/EOS_holiday V2_sRGB.svg');}
.bi-eos-helpdesk-v2:before{content: url('/icons/EOS_helpdesk V2_sRGB.svg');}
.bi-eos-helpdesk-v1:before{content: url('/icons/EOS_helpdesk V1_sRGB.svg');}
.bi-eos-heart:before{content: url('/icons/EOS_heart_sRGB.svg');}
.bi-eos-health:before{content: url('/icons/EOS_health_sRGB.svg');}
.bi-eos-handset:before{content: url('/icons/EOS_handset_sRGB.svg');}
.bi-eos-global-service:before{content: url('/icons/EOS_global service_sRGB.svg');}
.bi-eos-future:before{content: url('/icons/EOS_future_sRGB.svg');}
.bi-eos-form:before{content: url('/icons/EOS_form_sRGB.svg');}
.bi-eos-foresight:before{content: url('/icons/EOS_foresight_sRGB.svg');}
.bi-eos-flexidays:before{content: url('/icons/EOS_flexidays_sRGB.svg');}
.bi-eos-flexible-working time:before{content: url('/icons/EOS_flexible working time_sRGB.svg');}
.bi-eos-finish:before{content: url('/icons/EOS_finish_sRGB.svg');}
.bi-eos-facility-management:before{content: url('/icons/EOS_facility management_sRGB.svg');}
.bi-eos-exchange-v2:before{content: url('/icons/EOS_exchange V2_sRGB.svg');}
.bi-eos-exchange-v1:before{content: url('/icons/EOS_exchange V1_sRGB.svg');}
.bi-eos-error-culture:before{content: url('/icons/EOS_error culture_sRGB.svg');}
.bi-eos-energy-provider:before{content: url('/icons/EOS_energy provider_sRGB.svg');}
.bi-eos-employee:before{content: url('/icons/EOS_employee_sRGB.svg');}
.bi-eos-e-mail-v2:before{content: url('/icons/EOS_e-mail V2_sRGB.svg');}
.bi-eos-e-mail-v1:before{content: url('/icons/EOS_e-mail V1_sRGB.svg');}
.bi-eos-education:before{content: url('/icons/EOS_education_sRGB.svg');}
.bi-eos-download:before{content: url('/icons/EOS_download_sRGB.svg');}
.bi-eos-digital-working:before{content: url('/icons/EOS_digital working_sRGB.svg');}
.bi-eos-digital-processes-v3:before{content: url('/icons/EOS_digital processes V3_sRGB.svg');}
.bi-eos-digital-processes-v2:before{content: url('/icons/EOS_digital processes V2_sRGB.svg');}
.bi-eos-digital-processes-v1:before{content: url('/icons/EOS_digital processes V1_sRGB.svg');}
.bi-eos-delete:before{content: url('/icons/EOS_delete_sRGB.svg');}
.bi-eos-credit-card:before{content: url('/icons/EOS_credit card_sRGB.svg');}
.bi-eos-contact:before{content: url('/icons/EOS_contact_sRGB.svg');}
.bi-eos-company-v2:before{content: url('/icons/EOS_company V2_sRGB.svg');}
.bi-eos-company-v1:before{content: url('/icons/EOS_company V1_sRGB.svg');}
.bi-eos-company-sports-v2:before{content: url('/icons/EOS_company sports V2_sRGB.svg');}
.bi-eos-company-sports-v1:before{content: url('/icons/EOS_company sports V1_sRGB.svg');}
.bi-eos-communication-v2:before{content: url('/icons/EOS_communication V2_sRGB.svg');}
.bi-eos-communication-v1:before{content: url('/icons/EOS_communication V1_sRGB.svg');}
.bi-eos-collaboration:before{content: url('/icons/EOS_collaboration_sRGB.svg');}
.bi-eos-claim-number:before{content: url('/icons/EOS_claim number_sRGB.svg');}
.bi-eos-charge-to-claim-v1:before{content: url('/icons/EOS_charge to claim V1_sRGB.svg');}
.bi-eos-cancel-v2:before{content: url('/icons/EOS_cancel V2_sRGB.svg');}
.bi-eos-camera:before{content: url('/icons/EOS_camera_sRGB.svg');}
.bi-eos-call:before{content: url('/icons/EOS_call_sRGB.svg');}
.bi-eos-calendar:before{content: url('/icons/EOS_calendar_sRGB.svg');}
.bi-eos-bullhorn:before{content: url('/icons/EOS_bullhorn_sRGB.svg');}
.bi-eos-brandhub_design-guideline:before{content: url('/icons/EOS_brandhub_design guideline_sRGB.svg');}
.bi-eos-bike:before{content: url('/icons/EOS_bike_sRGB.svg');}
.bi-eos-bank:before{content: url('/icons/EOS_bank_sRGB.svg');}
.bi-eos-award:before{content: url('/icons/EOS_award_sRGB.svg');}
.bi-eos-attention:before{content: url('/icons/EOS_attention_sRGB.svg');}
.bi-eos-attachment:before{content: url('/icons/EOS_attachment_sRGB.svg');}
.bi-eos-artificial-intelligence:before{content: url('/icons/EOS_artificial intelligence_sRGB.svg');}
.bi-eos-arrow-up:before{content: url('/icons/EOS_arrow up_sRGB.svg');}
.bi-eos-arrow-simple-up:before{content: url('/icons/EOS_arrow simple up_sRGB.svg');}
.bi-eos-arrow-simple-right:before{content: url('/icons/EOS_arrow simple right_sRGB.svg');}
.bi-eos-arrow-simple-left:before{content: url('/icons/EOS_arrow simple left_sRGB.svg');}
.bi-eos-arrow-simple-down:before{content: url('/icons/EOS_arrow simple down_sRGB.svg');}
.bi-eos-arrow-right:before{content: url('/icons/EOS_arrow right_sRGB.svg');}
.bi-eos-arrow-left:before{content: url('/icons/EOS_arrow left_sRGB.svg');}
.bi-eos-arrow-down:before{content: url('/icons/EOS_arrow down_sRGB.svg');}
.bi-eos-app:before{content: url('/icons/EOS_app_sRGB.svg');}
.bi-eos-agile-working:before{content: url('/icons/EOS_agile working_sRGB.svg');}
.bi-eos-advancement:before{content: url('/icons/EOS_advancement_sRGB.svg');}
.bi-eos-holiday-v1:before{content: url('/icons/EOS_holiday V1_sRGB.svg');}
.bi-eos-company-car:before{content: url('/icons/EOS_company car_sRGB.svg');}
